import {
  Avatar,
  ChannelPreviewUIComponentProps,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

import React from "react";
import "./TeamChannelPreview.css";
import "../CommunityList/CommunityList.css";
import close from "../../assets/img/close.svg";

import { TeamTypingIndicator } from "../TeamTypingIndicator/TeamTypingIndicator";
import { DefaultGenerics } from "stream-chat";
import { useRefresh, useStores } from "../../hooks";
import { observer } from "mobx-react-lite";
import HCAvatar from "../HCAvatar";
import { Badge } from "@mui/material";
import { Channel } from "../../models";

type Props = ChannelPreviewUIComponentProps<DefaultGenerics> & {
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  loadChannels: () => Promise<void>;
};

export const TeamChannelPreview: React.FC<Props> = observer((props) => {
  const {
    channel,
    setActiveChannel,
    setIsCreating,
    setIsEditing,
    type,
    loadChannels,
  } = props;
  const { client } = useChatContext();
  const { chatStore, accountStore, configStore } = useStores();
  const { collectionList } = accountStore;
  const refresh = useRefresh();

  const { channel: activeChannel } = useChannelStateContext();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState<boolean>(false);

  const leaveCommunity = async () => {
    setLoading(true);
    await channel.removeMembers([client.userID!]);
    await loadChannels();
    setLoading(false);
  };
  const defaultName = "Hashchat Degen";
  const sourceId = new Channel(channel).getSourceId();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const image = collectionList.getImageByContractId(sourceId);

  const ChannelPreview = () => (
    <div className="team-channel-preview__item-community">
      <div className="community-left-side">
        <Avatar name={channel?.data?.name} size={24} />
        {`${channel?.data?.name?.slice(0, 20) ||
          (channel as any)?.data?.id?.slice(0, 20) ||
          defaultName
          }
            `}
      </div>
      <div className="leave-community-container">
        <img
          src={close}
          alt=""
          className="leave-community"
          onClick={(e) => {
            leaveCommunity();
            e.stopPropagation();
            chatStore.welcomeScreenVisible = true;
          }}
        ></img>
      </div>
    </div>
  );

  const DirectPreview = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user?.id !== client.userID
    );
    const member = members[0];

    // console.log("channel, countUnread: ", channel, channel.countUnread())

    if (members.length === 1) {
      return (
        <div className="team-channel-preview__item">
          <Badge badgeContent={channel.countUnread()} color="primary">
            <HCAvatar userId={member?.user?.id || ""} size={24} />
          </Badge>
          <TeamTypingIndicator type="list" />
        </div>
      );
    }

    return (
      <div className="channel-preview__item">
        <Badge badgeContent={channel.countUnread()} color="primary">

           {"Group: "}<HCAvatar userId={members[0].user?.id || ""} size={18} />
 
          {/* <HCAvatar userId={members[1].user?.id || ""} size={10} /> */}
        </Badge>
      </div>
    );
  };

  return (
    <div
      className={
        !chatStore.welcomeScreenVisible && channel?.id === activeChannel?.id
          ? "channel-preview__wrapper__selected"
          : "channel-preview__wrapper"
      }
      onClick={() => {
        setIsCreating(false);
        setIsEditing(false);
        configStore.hideSidebar();

        if (setActiveChannel) {
          setActiveChannel(channel);
          chatStore.hideWelcomeScreen();
          refresh();
        }
      }}
    >
      {type === "team" ? <ChannelPreview /> : <DirectPreview />}
    </div>
  );
});
