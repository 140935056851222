import { DeBankServiceImpl } from "./debank/debank.service.impl";
import { DeBankService } from "./debank/debank.service";
import { EnsService } from "./ens/ens.service";
import { EnsServiceImpl } from "./ens/ens.service.impl";
import { GnosisService } from "./gnosis/gnosis.service";
import { GnosisServiceImpl } from "./gnosis/gnosis.service.impl";
import { OpenSeaServiceImpl } from "./openSea/openSea.service.impl";
import { OpenSeaService } from "./openSea/openSea.service";
import {
  Services as SDKServices,
  AuthService,
  ChatService,
  GnoSafeSenderService,
} from "@hashchat/js-hashchat";
import { PoapService } from "./poap/poap.service";
import { PoapServiceImpl } from "./poap/poap.service.impl";

export class Services {
  deBankService: DeBankService = new DeBankServiceImpl();
  ensService: EnsService = new EnsServiceImpl();
  gnosisService: GnosisService = new GnosisServiceImpl();
  openSeaService: OpenSeaService = new OpenSeaServiceImpl();
  authService: AuthService;
  chatService: ChatService;
  safeSenderService: GnoSafeSenderService;
  poapService: PoapService = new PoapServiceImpl();

  constructor(chatServiceApiKey: string) {
    const sdkServices = new SDKServices(chatServiceApiKey);
    this.chatService = sdkServices.chatService;
    this.authService = sdkServices.authService;
    this.safeSenderService = sdkServices.safeSenderService;
  }
}

export const services = new Services(process.env.REACT_APP_STREAM_KEY!);
