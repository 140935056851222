import React, { useEffect, useMemo, useState } from "react";
import {
  Channel,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

import "./ChannelContainer.css";
import { ChannelInner } from "./ChannelInner";
import { ChannelEmptyState } from "../ChannelEmptyState/ChannelEmptyState";
import { CreateChannel } from "../CreateChannel/CreateChannel";
// eslint-disable-next-line
import { EditChannelName } from "../EditChannel/EditChannelName";
import { TeamMessageInput } from "../TeamMessageInput/TeamMessageInput";

import type { ChannelFilters, Message } from "stream-chat";

import { MessageType } from "@hashchat/js-hashchat";
import { EncryptedTeamMessage } from "../TeamMessage/EncryptedTeamMessage";
import { useServices, useStores } from "../../hooks";
import { Channel as ChannelModel, Transaction } from "../../models";
import { WelcomeContainer } from "./containers/Welcome/WelcomeContainer";
import { observer } from "mobx-react-lite";
import { Transactions } from "./containers/transactions/transactions.container";
import { ChannelContainerProps } from "./ChannelContainerProps";
import { ThreadHeader } from "./containers/thread-header/thread-header.container";
import { TransactionsHeader } from "./containers/transactions-header/transactions-header.container";

export const ChannelContainer: React.FC<ChannelContainerProps> = observer(
  (props) => {
    const { createType, isCreating, isEditing, setIsCreating, setIsEditing } =
      props;
    const { channel: _channel } = useChannelStateContext();
    const { chatService, gnosisService } = useServices();
    const {
      chatStore: { welcomeScreenVisible, activeSafeTransactionList },
    } = useStores();
    // const {
    //   authStore: { chainId },
    //   accountStore: { gnosisSafeList },
    // } = useStores();
    const [activeTransaction, setActiveTransaction] = useState<
      Transaction | undefined
    >();
    const activeChannel = useMemo(() => new ChannelModel(_channel), [_channel]);
    const channelIsASafe: boolean = (activeChannel.cid as string)?.includes(
      "team:safe_eip155_"
    );

    const safeId =
      (activeChannel.cid as string)
        ?.replace("team:safe_eip155_", "")
        .split("_")
        .pop() ?? "";
    // const safe = gnosisSafeList.getItemById(safeId) as GnosisSafe;
    const [showTransactions, setShowTransactions] = useState(false);
    const [pinsOpen, setPinsOpen] = useState(false);
    const { channel } = useChatContext();

    useEffect(() => {
      setShowTransactions(false);
    }, [channel]);

    useEffect(() => {
      const loadTransactions = async () => {
        if (channelIsASafe) {
          await gnosisService.getTransactions(safeId);
        } else {
          activeSafeTransactionList.clear();
        }
      };

      loadTransactions();
    }, [
      activeSafeTransactionList,
      gnosisService,
      safeId,
      channelIsASafe,
      channel,
    ]);

    if (isCreating) {
      const filters: ChannelFilters[] = [];
      return (
        <div className="channel__container">
          <CreateChannel {...{ createType, filters, setIsCreating }} />
        </div>
      );
    }

    /**
     * NOTE: Why channel list has 1 hidden channel on load?
     */
    if (welcomeScreenVisible) {
      return <WelcomeContainer />;
    }

    if (!channel) {
      return <></>;
    }

    if (isEditing) {
      const filters: ChannelFilters[] = [];

      if (channel.state.members) {
        const channelMembers = Object.keys(channel.state.members);

        if (channelMembers.length) {
          // @ts-expect-error
          filters.id = { $nin: channelMembers };
        }
      }

      return (
        <div className="channel__container">
          <EditChannelName {...{ filters, setIsEditing }} />
        </div>
      );
    }

    const sendMessage = async (channelId: string, message: Message) => {
      const response = await chatService.send(
        { type: MessageType.GET_STREAM, body: message },
        channelId
      );

      if (response.data) {
        return {
          message: response.data,
        } as any;
      }
    };

    return (
      <div className="channel__container">
        <div className="channels-wrapper"></div>
        {showTransactions && (
          <>
            <Channel
              EmptyStateIndicator={ChannelEmptyState}
              Input={TeamMessageInput}
              doSendMessageRequest={(channelId: string, message: Message) => {
                return sendMessage(channelId, message);
              }}
              Message={(messageProps) => (
                <EncryptedTeamMessage {...messageProps} {...{ setPinsOpen }} />
              )}
              ThreadHeader={(threadProps) => (
                <ThreadHeader {...threadProps} {...{ setPinsOpen }} />
              )}
              TypingIndicator={() => null}
            >
              <div>
                <ChannelInner
                  {...{
                    pinsOpen,
                    setIsEditing,
                    setPinsOpen,
                    setShowTransactions,
                    showTransactions,
                  }}
                />
                {/*Queued*/}
                <div className="safe-transaction-container">
                  <TransactionsHeader
                    transactionList={activeSafeTransactionList.getQueued()}
                  />
                  {activeSafeTransactionList.getQueued().map((transaction) => (
                    <Transactions
                      key={transaction.id}
                      activeTransaction={activeTransaction}
                      safeId={safeId}
                      transaction={transaction}
                      setActiveTransaction={setActiveTransaction}
                    />
                  ))}
                </div>
                {/*Executed*/}
                <div className="safe-transaction-container">
                  <TransactionsHeader
                    isExecuted
                    transactionList={activeSafeTransactionList.getExecuted()}
                  />
                  {activeSafeTransactionList
                    .getExecuted()
                    .map((transaction) => (
                      <Transactions
                        key={transaction.id}
                        activeTransaction={activeTransaction}
                        safeId={safeId}
                        transaction={transaction}
                        setActiveTransaction={setActiveTransaction}
                      />
                    ))}
                </div>
              </div>
            </Channel>
          </>
        )}
        {!showTransactions && (
          <Channel
            EmptyStateIndicator={ChannelEmptyState}
            Input={TeamMessageInput}
            doSendMessageRequest={(channelId: string, message: Message) => {
              return sendMessage(channelId, message);
            }}
            Message={(messageProps) => (
              <EncryptedTeamMessage {...messageProps} {...{ setPinsOpen }} />
            )}
            ThreadHeader={(threadProps) => (
              <ThreadHeader {...threadProps} {...{ setPinsOpen }} />
            )}
            TypingIndicator={() => null}
          >
            <ChannelInner
              {...{
                pinsOpen,
                setIsEditing,
                setPinsOpen,
                setShowTransactions,
                showTransactions,
              }}
            />
          </Channel>
        )}
      </div>
    );
  }
);
