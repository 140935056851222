import React from "react";

import "./TeamChannelList.css";
import "../CommunityList/CommunityList.css";

import type { ChannelListMessengerProps } from "stream-chat-react";
import { AddChannel } from "../../assets";

export type TeamChannelListProps = ChannelListMessengerProps & {
  setCreateType: React.Dispatch<React.SetStateAction<string>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  children?: React.ReactNode | React.ReactNode[];
};

const ChannelList: React.FC<TeamChannelListProps> = (props) => {


  const {
    children,
    error = false,
    loading,
    setCreateType,
    setIsCreating,
    setIsEditing,
    type,
  } = props;


  if (error) {
    return type === "team" ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message loading">
          {type === "team" ? "Channels" : "Messages"} loading....
        </p>
      </div>
    );
  }

  const isTeam = type === "team";

  return (
    <div className="team-channel-list">
      <div className="team-channel-list__header">
        <p className="team-channel-list__header__title">
          {isTeam ? "My Communities" : "Direct Messages"}
        </p>


        {!isTeam && (
          <AddChannel
            {...{ setCreateType, setIsCreating, setIsEditing }}
            type={"messaging"}
          />
        )}
      </div>
      <div className="team-channel-list-container">{children}</div>
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);
