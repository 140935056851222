import { AssetType } from "caip";
import React, { useCallback, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import {
  accountIdFromUserId,
  assetIdToChannelId,
  poapAccountIdToChannelId,
} from "@hashchat/js-hashchat";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CommunityList.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Channel, Contract, GnosisSafe, Poap } from "../../models";
import { useServices, useStores } from "../../hooks";
import { observer } from "mobx-react-lite";
import { stores } from "../../stores";
import { TeamTypingIndicator } from "../TeamTypingIndicator/TeamTypingIndicator";
import { DebankChainID } from "../../types/";
import { Channel as StreamChannel } from "stream-chat/dist/types/channel";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

const LoadingUI = () => {
  return (
    <div className="team-channel-list">
      <p className="team-channel-list__message loading">
        Loading Communities....
      </p>
    </div>
  );
};

const ChannelList = observer(() => {
  const { client, setActiveChannel } = useChatContext();
  const { accountStore, chatStore } = useStores();
  const {
    contractList,
    //  userTokenList,
    gnosisSafeList,
    poapList,
  } = accountStore;
  const { channelList } = chatStore;
  const { authStore } = stores;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deBankService,
    gnosisService,
    openSeaService,
    poapService,
  } = useServices();
  const [error, setError] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  // const [
  // openTokens,
  //   setOpenTokens,
  // ] = useState(false);
  const [openNFTS, setOpenNFTS] = useState(false);
  // const [openSafes, setOpenSafes] = useState(false);
  const [openPoaps, setOpenPoaps] = useState(false);

  //accordion functionality
  // const openTokenAccordion = () => {
  //   setOpenTokens(!openTokens);
  //   setOpenNFTS(false);
  //   setOpenSafes(false);
  // };

  const openNFTAccordion = () => {
    // setOpenTokens(false);
    setOpenNFTS(!openNFTS);
    // setOpenSafes(false);
  };

  // const openSafesAccordion = () => {
  //   // setOpenTokens(false);
  //   setOpenNFTS(false);
  //   setOpenSafes(!openSafes);
  // };

  const openPoapsAccordion = () => {
    // setOpenTokens(false);
    setOpenNFTS(false);
    // setOpenSafes(false);
    setOpenPoaps(!openPoaps);
  };

  //tokens
  // const loadUserTokens = useCallback(async () => {
  //   if (client.userID == null) return;

  //   setError(null);
  //   setLoading(true);
  //   try {
  //     const channelIds = channelList.getIds();
  //     const accountId = accountIdFromUserId(client.userID!);

  //     const response = await deBankService.loadTokens(accountId, channelIds);
  //     // console.log(response);
  //     if (response.data) {
  //       userTokenList.replace(response.data);
  //       /**
  //        * Remove already added channels from the list
  //        */
  //       channelList.map((channel) =>
  //         userTokenList.removeWithChannelId(channel.cid)
  //       );
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setError(error);
  //   }
  //   setLoading(false);
  // }, [channelList, client.userID, deBankService, userTokenList]);

  // React.useEffect(() => {
  //   loadUserTokens();
  // }, [client, loadUserTokens, channelList.id]);

  // nfts
  const loadNFTContracts = useCallback(async () => {
    if (client.userID == null) return;

    setError(null);
    setLoading(true);

    try {
      const userWalletAddress = accountIdFromUserId(client.userID!);
      const response = await openSeaService.getCollectionContracts(
        userWalletAddress.address
      );

      console.log("contracts ", response);

      if (response.data) {
        channelList.map((channel) =>
          contractList.removeWithChannelId(channel.cid)
        );
      } else {
        setError(error);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setLoading(false);
  }, [channelList, client.userID, error, contractList, openSeaService]);

  React.useEffect(() => {
    loadNFTContracts();
  }, [client, loadNFTContracts, channelList.id]);

  // TODO: Delete below code after testing
  // React.useEffect(() => {
  //   const load = async () => {
  //     const res = await gnosisService.getTransactions(
  //       "0x5A2966941428b8988B7f15323c9814958BeDdBf2",
  //       { executed: false, nonce_gte: 7 }
  //     );
  //     console.log("Gnosis transactions response: ", res);
  //   };
  //   load();
  // }, [gnosisService]);

  //gnosis safes
  const loadSafes = useCallback(async () => {
    if (client.userID == null) return;

    setError(null);
    setLoading(true);
    try {
      const channelIds = channelList.getIds();
      const userAccount = accountIdFromUserId(client.userID!);

      const response = await gnosisService.loadAccountSafes(
        userAccount,
        channelIds
      );

      if (response.data) {
        gnosisSafeList.replace(response.data);
        channelList.map((channel) =>
          gnosisSafeList.removeWithChannelId(channel.cid)
        );
      } else {
        setError(error);
      }
      // setAccountSafes(safes);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setLoading(false);
  }, [client.userID, channelList, gnosisService, gnosisSafeList, error]);

  React.useEffect(() => {
    loadSafes();
  }, [client, loadSafes, channelList.id]);

  //poaps
  const loadPoaps = useCallback(async () => {
    if (client.userID == null || authStore.authSession == null) return;
    setError(null);
    setLoading(true);

    try {
      const response = await poapService.getPoaps(
        authStore.authSession.accountId.address
      );
      if (response.data) {
        channelList.map((channel) => poapList.removeWithChannelId(channel.cid));
      } else {
        setError(error);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setLoading(false);
  }, [
    authStore.authSession,
    channelList,
    client.userID,
    error,
    poapList,
    poapService,
  ]);

  React.useEffect(() => {
    loadPoaps();
  }, [loadPoaps, client, channelList.id]);

  if (error) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    );
  }

  // const joinCommunity = async (userToken: UserToken) => {
  //   setLoading(true);
  //   const accountId = accountIdFromUserId(client.userID!);
  //   const assetId = new AssetType({
  //     chainId: accountId.chainId,
  //     assetName: {
  //       namespace: "erc20",
  //       reference: userToken.id,
  //     },
  //   });
  //   const channelId = assetIdToChannelId(assetId);
  //   const channel = client.channel("team", channelId, {
  //     name: userToken.name,
  //   });

  //   try {
  //     await channel.create();
  //   } catch (e) {}

  //   await channel.addMembers([client.userID!]);

  //   userTokenList.removeWithChannelId(channel.cid);
  //   setActiveChannel(channel);
  //   // chatStore.refreshChannels();
  // chatStore.hideWelcomeScreen();
  //   setLoading(false);
  // };

  // Ensures that a channel id is valid before creation
  const validateChannelId = (channelId: string): boolean => {
    const dummyChannel = new Channel({
      id: channelId,
      cid: `team:${channelId}`,
    } as StreamChannel<DefaultStreamChatGenerics>);
    const isValid = dummyChannel.isValid();

    if (!isValid) {
      console.error("Invalid channel ID: " + channelId);
    }

    return isValid;
  };

  const joinCommunityNFTCollection = async (contract: Contract) => {
    console.log("joinCommunityNFTCollection", contract.id);
    setLoading(true);
    // const userAccount = accountIdFromUserId(client.userID!); // using chain from user is very wrong here.
    // uses debank service and user's wallet client.userID! to getAllNFTs this will have contract and CHAIN
    // Match that list with the contract provided to this function.  Get the chain from debank.
    // save in nftChainId below.

    const userAddress = accountIdFromUserId(client.userID!).address;
    let nftChainId = "1"; // initial guess
    const supportedNFTChains = [
      DebankChainID.eth,
      DebankChainID.matic,
      DebankChainID.xdai,
    ];

    for (const chain of supportedNFTChains) {
      const index = supportedNFTChains.indexOf(chain);
      const { data: userNftList } = await deBankService.getUserNftList(
        userAddress,
        DebankChainID[supportedNFTChains[index]]
      );
      const matchedUserNft = userNftList?.findByContractId(contract.id);

      if (matchedUserNft) {
        nftChainId = supportedNFTChains[index].toString();
        break;
      }
    }

    const assetId = new AssetType({
      chainId: {
        namespace: "eip155",
        reference: nftChainId, // numerical value as string required here for compatible channel names
      },
      assetName: {
        namespace: "erc721",
        reference: contract.id,
      },
    });
    console.log("found nft and creating channel for: ", assetId);
    // if of type non-fungible, set channelID = contract address
    // if of type semi-fungible, set channelID = token ID
    const channelId = assetIdToChannelId(assetId);
    // if of type non-fungible OR semi-fungible, set channel = the name inside the collection object

    if (!validateChannelId(channelId)) {
      return;
    }

    const channel = client.channel("team", channelId, {
      name: contract.name,
    });

    try {
      await channel.create();
    } catch (e) {}

    await channel.addMembers([client.userID!]);
    await loadNFTContracts();
    contractList.removeWithChannelId(channel.cid);
    setActiveChannel(channel);
    chatStore.hideWelcomeScreen();
    setLoading(false);
  };

  // const joinCommunityGNO = async (gnosisSafe: GnosisSafe) => {
  //   setLoading(true);
  //   const channelId = safeAccountIdToChannelId(gnosisSafe.safeAccountId);

  //   if (!validateChannelId(channelId)) {
  //     return;
  //   }

  //   const channel = client.channel("team", channelId, {
  //     name: channelId,
  //   });

  //   try {
  //     await channel.create();
  //   } catch (e) {}
  //   await channel.addMembers([client.userID!]);
  //   await loadSafes();
  //   gnosisSafeList.removeWithChannelId(channel.cid);
  //   setActiveChannel(channel);
  //   chatStore.hideWelcomeScreen();
  //   setLoading(false);
  // };

  const joinCommunityPoap = async (poap: Poap) => {
    setLoading(true);
    const channelId = poapAccountIdToChannelId(poap.poapAccountId);

    if (!validateChannelId(channelId)) {
      return;
    }

    const channel = client.channel("team", channelId, {
      name: poap.name,
    });

    try {
      await channel.create();
    } catch (e) {}
    await channel.addMembers([client.userID!]);
    await loadPoaps();
    poapList.removeWithChannelId(channel.cid);
    setActiveChannel(channel);
    chatStore.hideWelcomeScreen();
    setLoading(false);
  };

  return (
    <div className="master-div">
      {/* <Accordion expanded={openTokens} onClick={openTokenAccordion}>
        <AccordionSummary
          className="accordion-title"
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "white",
              }}
            />
          }
          sx={{
            height: "5px",
            color: "white",
            backgroundColor: "#26282b",
          }}
        >
          <p className="token-type">Eligible Token Communities</p>
        </AccordionSummary> */}
      {/* <AccordionDetails
          sx={{
            backgroundColor: "#26282b",
            padding: "0px",
            paddingLeft: "7px",
            margin: "0px",
          }}
        > */}
      {/* <div className="token-list-container">
            {loading ? (
              <div className="team-channel-list">
                <p className="team-channel-list__message loading">
                  Loading Communities....
                </p>
              </div>
            ) : (
              <div className="token-list-container">
                {loading ? (
                  <LoadingUI />
                ) : (
                  <>
                    {userTokenList.map((userToken) => {
                      return (
                        <div key={userToken.id}>
                          <div className="channel-preview__item">
                            <div className="token-channel__item">
                              <Avatar
                                image={userToken.logoUrl}
                                name={userToken.symbol}
                                size={24}
                              />
                              <p>{userToken.symbol}</p> */}
      {/* <TeamTypingIndicator type="list" /> */}
      {/* </div>
                            <div className="btn-container">
                              <button
                                className="join-btn"
                                onClick={() => {
                                  joinCommunity(userToken);
                                }}
                              >
                                Join
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </AccordionDetails> */}
      {/* </Accordion> */}
      <Accordion expanded={openNFTS} onClick={openNFTAccordion}>
        <AccordionSummary
          className="accordion-title"
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "white",
              }}
            />
          }
          sx={{
            height: "5px",
            color: "white",
            backgroundColor: "#26282b",
          }}
        >
          <p className="token-type">NFTs</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#26282b",
            padding: "0px",
            paddingLeft: "7px",
            margin: "0px",
          }}
        >
          <div className="token-list-container">
            {loading ? (
              <LoadingUI />
            ) : (
              <div className="token-list-container">
                {contractList
                  .getContractChannels(channelList)
                  .map((contract) => {
                    return (
                      <div key={contract.id}>
                        <div className="channel-preview__item">
                          <div className="token-channel__item">
                            <Avatar
                              image={contract.imageUrl}
                              name={contract.name}
                              size={24}
                            />
                            <p>{contract.name}</p>
                            <TeamTypingIndicator type="list" />
                          </div>
                          <div className="btn-container">
                            <button
                              className="join-btn"
                              onClick={() => {
                                joinCommunityNFTCollection(contract);
                              }}
                            >
                              Join
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={openSafes} onClick={openSafesAccordion}>
        <AccordionSummary
          className="accordion-title"
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "white",
              }}
            />
          }
          sx={{
            height: "5px",
            color: "white",
            backgroundColor: "#26282b",
          }}
        >
          <p className="token-type">Owned Gnosis Safes</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#26282b",
            padding: "0px",
            paddingLeft: "7px",
            margin: "0px",
          }}
        >
          <div className="token-list-container">
            {loading ? (
              <LoadingUI />
            ) : (
              <div className="token-list-container">
                {gnosisSafeList
                  .getGnosisChannels(channelList)
                  .map((gnosisSafe) => {
                    return (
                      <div key={gnosisSafe.safeAccountId.toString()}>
                        <div className="channel-preview__item single">
                          {/* <Avatar
                              image={accountNFT.content}
                              name={accountNFT.name}
                              size={24}
                            /> */}
                          {/*
                          <p>{gnosisSafe.displayName}</p>
                          {/* <TeamTypingIndicator type="list" /> */}
                          {/*
                          <div className="btn-container">
                            <button
                              className="join-btn"
                              onClick={() => {
                                joinCommunityGNO(gnosisSafe);
                              }}
                            >
                              Join
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion> */}
      <Accordion expanded={openPoaps} onClick={openPoapsAccordion}>
        <AccordionSummary
          className="accordion-title"
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: "white",
              }}
            />
          }
          sx={{
            height: "5px",
            color: "white",
            backgroundColor: "#26282b",
          }}
        >
          <p className="token-type">Poaps</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#26282b",
            padding: "0px",
            paddingLeft: "7px",
            margin: "0px",
          }}
        >
          <div className="token-list-container">
            {loading ? (
              <LoadingUI />
            ) : (
              <div className="token-list-container">
                {poapList.map((poap) => {
                  return (
                    <div key={poap.id}>
                      <div className="channel-preview__item">
                        <div className="token-channel__item">
                          <Avatar
                            image={poap.event.image_url}
                            name={poap.name}
                            size={24}
                          />
                          <p className="token-name">{poap.name}</p>
                          {/* <TeamTypingIndicator type="list" /> */}
                        </div>
                        <div className="btn-container">
                          <button
                            className="join-btn"
                            onClick={() => {
                              joinCommunityPoap(poap);
                            }}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <IconFooter /> */}
    </div>
  );
});

export const CommunityList = React.memo(ChannelList);
